import { render, staticRenderFns } from "./rescrutinyNotice.vue?vue&type=template&id=45f1a6e8&scoped=true&"
import script from "./rescrutinyNotice.vue?vue&type=script&lang=js&"
export * from "./rescrutinyNotice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f1a6e8",
  null
  
)

export default component.exports